import color from "@definitions/mui/color";
import { makeStyles } from "@mui/styles";
import { IUseStyles } from "./interface";

const useStyles = ({ isVisible, mdClient }: IUseStyles) => {
  return makeStyles(() => ({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: `${color.white} !important`,
      height: 70,
      margin: "0 auto",
      boxShadow: isVisible ? "0 0 8px 0 rgba(0, 0, 0, 10%)" : "none",
      "& a": {
        textDecoration: "none",
      },
    },
    contentWrapper: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    clickableIcon: {
      "& .icon": {
        height: 24,
        width: 24,
      },
      "&:hover": {
        background: color.primary.soft,
      },
      alignItems: "center",
      borderRadius: 8,
      cursor: "pointer",
      display: "flex",
      height: 40,
      justifyContent: "center",
      minHeight: 40,
      minWidth: 40,
      transition: "200ms",
      width: 40,
    },
    signButton: {
      padding: mdClient ? "8px 24px" : undefined,
      fontSize: "16px",
      minWidth: mdClient ? 0 : 120,
    },
  }))();
};
export default useStyles;
