import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { css } from "@emotion/react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import { SelectChangeEvent, useMediaQuery, useTheme } from "@mui/material";
import { logo } from "@config/images";
import { useAccountContext } from "@contexts/AccountProvider";
import Button from "@ds/components/Button";
import Text from "@ds/components/Text";

import useAction from "../../../_hooks/useAction";
// import NavbarMenu from "./components/NavbarMenu/NavbarMenu";
import type { IHeaderLPComponentProps } from "./interface";
import GlobalSearch from "./lib/GlobalSearch";
// import MenuDrawer from "./lib/MenuDrawer";
// import Notification from "./lib/Notification";
// import Profile from "./lib/Profile";
// import SelectLanguage from "./lib/SelectLanguage";
import useStyles from "./styles";

const NavbarMenu = dynamic(() => import("./components/NavbarMenu/NavbarMenu"));
const MenuDrawer = dynamic(() => import("./lib/MenuDrawer"));
const Notification = dynamic(() => import("./lib/Notification"));
const Profile = dynamic(() => import("./lib/Profile"));
const SelectLanguage = dynamic(() => import("./lib/SelectLanguage"));

/**
 * It is option to used styling Toolbar from MUI
 * for working in server-side rendering correctly
 * refer: https://mui.com/material-ui/guides/styled-engine/#how-to-switch-to-styled-components
 */
export const ToolbarStyled = css({
  "@media (max-width: 1199px)": {
    width: "100%",
    padding: "0 16px 0",
  },
  "@media (min-width: 1200px)": {
    width: 1200,
  },
});

const isAllowLogin = (process.env.NEXT_PUBLIC_ALLOW_LOGIN ?? "true") === "true";

export const HeaderLP: React.FC<IHeaderLPComponentProps> = ({ featureNotif = [] }) => {
  const {
    language,
    mdClient,
    dataNavbar,
    loadNavbar,
    businessSolution,
    confirmation,
    setConfirmation,
    clearConfirmation,
    setLanguage,
  } = useAction();
  const router = useRouter();
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation("header");
  const { isAuth, logout } = useAccountContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles({ mdClient, isVisible, isAdmin: false });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const windowScroll = window.scrollY;
      if (windowScroll > 45) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  }, []);

  const _handleChangeLanguage = (e: SelectChangeEvent<string>) => {
    const { pathname, asPath, query } = router;
    router.push({ pathname, query }, asPath, { locale: e.target.value });
    setLanguage(e.target.value);
  };

  return (
    <Box flexGrow={1}>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar disableGutters={true} css={ToolbarStyled}>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Box display="flex" alignItems="center">
              {isMobile && <MenuDrawer {...{ dataNavbar, businessSolution }} />}
              <Link href="/" passHref>
                <a>
                  <Box display="flex">
                    <img
                      src={logo}
                      alt="MyTEnS logo"
                      width={isMobile ? 80 : 105}
                      height={isMobile ? 35 : 45}
                    />
                  </Box>
                </a>
              </Link>
              {!isMobile && (
                <Box ml={3}>
                  <NavbarMenu
                    dataNavbar={dataNavbar}
                    loadNavbar={loadNavbar}
                    businessSolution={businessSolution}
                    t={t}
                  />
                </Box>
              )}
            </Box>
            <Box display="flex" alignItems="center">
              <GlobalSearch />
              <Box display="flex" height={30}>
                <Divider orientation="vertical" flexItem sx={{ ml: !mdClient ? "16px" : "4px" }} />
              </Box>
              <Box display="flex" alignItems="center" ml="8px">
                <SelectLanguage
                  onChange={(event: SelectChangeEvent<string>) => _handleChangeLanguage(event)}
                  value={language}
                />
              </Box>
              {isAuth && (
                <>
                  <Notification feature={featureNotif} language={language} />
                  <Box display="flex" height={30}>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ ml: isMobile ? "4px" : "4px" }}
                    />
                  </Box>
                </>
              )}
              {isAuth && (
                <Profile
                  isLandingpage={true}
                  logoutProps={{
                    handleLogout: logout,
                    clearConfirmation: clearConfirmation,
                    confirmation: confirmation,
                    setConfirmation: setConfirmation,
                  }}
                />
              )}
              {!isAuth && isAllowLogin && (
                <Box ml={2}>
                  <Link href="/login" passHref>
                    <a>
                      <Button className={classes.signButton}>
                        <Text color="white" variant="body1">
                          {t("button")}
                        </Text>
                      </Button>
                    </a>
                  </Link>
                </Box>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
